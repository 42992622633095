import './App.css';

import React, { Component } from 'react';


import GraphD3 from './graph/GraphD3';

import logo from './imgs/logo.png';

import help_pdf from './res/AR-Graph-Passage-Search-Guidelines_20210714.pdf'
import { timeThursdays } from 'd3';

//import GraphRD3 from './graph/GraphRD3';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodes: new Set(),
      links: new Set(),
      duplicate_prevention_set: new Set(),
      relations: {},
      classification_checkbox_state: true,
      term_checkbox_state: true,
      wiki_checkbox_state: false,
      api_key: "b10225791d2a478c9ff3d8cd106ad65a",
      term: "metal",
      classification: "A61K",
      //classification: "A01.236",
      count: 10,
      selected_relations: [],
      selected_relations_text: [],
      new_node_name: "",
      node1: "",
      node2: "",
      domain: "patent",
      max_relations: 20,
      similarity_threshold: 0.5,
      show_help_popup: false,
      domain_similarity: { "patent": ["PatBERT", "SciBERT"], "covid": ["SciBERT"]},
      selected_similarity: "SciBERT",
      onto_classes_EPO: {},
      onto_classes_CORD: {},
      current_view: 0,
      current_view_selected: null,
      popout_selected_relationship: false
    }

    this.state.onto_classes_EPO = require('./res/onto_classes_EPO.json')
    this.state.onto_classes_CORD = require('./res/onto_classes_CORD.json')

    this.classification_input_reference = React.createRef()
    this.term_input_reference = React.createRef()
    this.graph_reference = React.createRef()
    
    this.onClassificationCheckboxStateChange = this.onClassificationCheckboxStateChange.bind(this);
    this.onTermCheckboxStateChange = this.onTermCheckboxStateChange.bind(this);
    this.onWikiCheckboxStateChange = this.onWikiCheckboxStateChange.bind(this);
    this.onSubmitClicked = this.onSubmitClicked.bind(this)
    this.doQuery = this.doQuery.bind(this)
    this.onApiKeyTextChange = this.onApiKeyTextChange.bind(this)
    this.onTermTextChange = this.onTermTextChange.bind(this)
    this.onClassificationTextChange = this.onClassificationTextChange.bind(this)
    this.onClickLink = this.onClickLink.bind(this)
    this.onClickBubble = this.onClickBubble.bind(this)
    this.onDownloadClicked = this.onDownloadClicked.bind(this)
    this.onAddNodeClicked = this.onAddNodeClicked.bind(this)
    this.onClickNode = this.onClickNode.bind(this)
    this.onNewNodeNameTextChange = this.onNewNodeNameTextChange.bind(this)
    this.onAddLinkClicked = this.onAddLinkClicked.bind(this)
    //this.onChangeRelationClicked = this.onChangeRelationClicked.bind(this)
    //this.onRemoveRelationClicked = this.onRemoveRelationClicked.bind(this, e, relation)
    //this.onRelationTextChange = this.onRelationTextChange.bind(this)
    this.onQueryRelationClicked = this.onQueryRelationClicked.bind(this)
    this.onRemoveNodeClicked = this.onRemoveNodeClicked.bind(this)
    this.updateDownloadProgress = this.updateDownloadProgress.bind(this)
    this.onMaxRelationsChange = this.onMaxRelationsChange.bind(this)
    this.onSimilarityThresholdChange = this.onSimilarityThresholdChange.bind(this)

  

    this.graph = new GraphD3()
  }


  onSimilarityThresholdChange(event){
    this.setState({
      nodes: new Set(),
      links: new Set(),
      similarity_threshold: parseFloat(event.target.value)
    }, () => this.processNodes(Object.values(this.state.relations), this.state.selected_similarity))
  }

  onMaxRelationsChange(event){
    this.setState({max_relations: parseInt(event.target.value)})
  }

  onApiKeyTextChange(event){
    this.setState({api_key: event.target.value})
  }

  onTermTextChange(event){
    this.setState({term: event.target.value})
  }

  onClassificationTextChange(event){
    this.setState({classification: event.target.value})
  }
  
  
  onClassificationCheckboxStateChange({target}){
    this.classification_input_reference.current.hidden = this.state.classification_checkbox_state

    this.setState(prevState => ({ 
      classification_checkbox_state: !prevState.classification_checkbox_state,
      classification: ""
    }))
  }

  onTermCheckboxStateChange({target}){
    this.term_input_reference.current.hidden = this.state.term_checkbox_state

    this.setState(prevState => ({ 
      term_checkbox_state: !prevState.term_checkbox_state,
      term: ""
    }))
  }

  onWikiCheckboxStateChange({target}){
    this.setState(prevState => ({
      wiki_checkbox_state: !this.state.wiki_checkbox_state
    }))
  }

  updateGraph() {
    const graph_data = {
      nodes: Array.from(this.state.nodes),
      links: Array.from(this.state.links),
      circles: this.getCircleData()
      //threshold: this.state.similarity_threshold
    };


    console.log(graph_data)
    const svg_graph = this.graph.draw(graph_data, this.onClickLink, this.onClickNode, this.onClickBubble, this.graph_reference)

    try{
      document.getElementById("graph").replaceChild(svg_graph, this.svg_graph)
    } catch(err){}

    this.svg_graph = svg_graph
  }

  onSubmitClicked(event){
    event.preventDefault();
    this.doQuery(this.state.term_checkbox_state, this.state.classification_checkbox_state, this.state.domain, this.state.classification, this.state.term)
  }
    
  doQuery(search_for_term, search_for_class, domain, classification, term){
    if(this.state.api_key == "demo" || this.state.api_key == ""){
      alert("Please enter a valid api key.")
      return
    }

    const payload = JSON.stringify({ api_key: this.state.api_key, return_type: 'json', window_size: this.state.max_relations, "similarity_type": this.state.selected_similarity})
    const data = new FormData();
    
    if(classification != "Unclassified"){
      classification = classification.toUpperCase()
    }
    term = term.toLowerCase()
    
    data.append("json", JSON.stringify(payload));

    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: payload
    }

    if(domain == "covid"){
      domain = "science"
    }
    
    var base_url = "https://api.artificialresearcher.com/"
    //var base_url = "http://localhost:5000/"
    
    
    var url = ""
    if(search_for_term){  
      if(search_for_class){
        url = base_url + `ontology/v2/${domain}/${classification}/${term}`
      } else {
        url = base_url + `ontology/v2/${domain}/all/${term}`
      }
    } else {
      if(search_for_class){
        url = base_url + `ontology/v2/${domain}/${classification}`
      } else {
        alert("You can't search for nothing.")
        return
      }
    }

    console.log(url)

    this.setState({is_loading: true})
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        this.setState({
          selected_relations: [],
          nodes: new Set(),
          links: new Set(),
          duplicate_prevention_set: new Set(),
          relations: {},
          is_loading: false,
          current_view: 3
        })
        this.processNodes(data['search_result'], this.state.selected_similarity)
      }).catch(error =>{
          alert("There was an error. Please try again.");
          console.log(error);
          this.setState({is_loading: false})
      });
  }

 

  processNodes(relations, selected_similarity) {
    const nodes = new Set()
    const links = new Set()
    const duplicate_prevention_set = new Set()

    const all_relations = this.state.relations

    for (var relation of relations){
      const general = relation["GENERAL"]
      const specific = relation["SPECIFIC"]
  
      delete relation["METADATA"]

      all_relations[general + "_" + specific + "_" + relation["SOURCE_TEXT"]] = relation
      

      var duplicate_relation = false
      if(relation["SIMILARITY"][selected_similarity] >= this.state.similarity_threshold) {
        if(!duplicate_prevention_set.has(general)){
          nodes.add({id: general})
          duplicate_prevention_set.add(general)
          duplicate_relation |= true
        }
        if(!duplicate_prevention_set.has(specific)){
          nodes.add({id: specific})
          duplicate_prevention_set.add(specific)
          duplicate_relation |= true
        }
        //Allow duplicate links because we can have several duplicate relationships
        links.add({source: general, target: specific})
        if(duplicate_relation){

        }
      }
    }

    this.setState({
      nodes: nodes,
      links: links,
      relations: all_relations
    }, () => this.updateGraph())
  }

  onClickNode(nodeId){
    this.setState({
      node1: this.state.node2,
      node2: nodeId
    })
  };
  
  onClickLink(source, target) {
    const selected_relations = []
    const selected_relations_text = []
    for (var relation of Object.keys(this.state.relations)){
      if(relation.startsWith(source + "_" + target + "_")){
        selected_relations.push(relation)
        selected_relations_text.push(JSON.stringify(this.state.relations[relation], null, 2))
      }
    }
    console.log("selected_relations")
    console.log(selected_relations)
    console.log("selected_relations_text")
    console.log(selected_relations_text)
    this.setState({
      selected_relations: selected_relations,
      selected_relations_text: selected_relations_text
    })
  };

  onClickBubble(bubbleId){
    var view_level = 0
    if(bubbleId.length == 1){
      view_level = 1
    } else if(bubbleId.length == 3) {
      view_level = 2
    } else if(bubbleId.length == 4 || bubbleId.length == 7 || bubbleId == "Unclassified") {
      view_level = 3
      this.doQuery(false, true, this.state.domain, bubbleId, "")
    }

    this.setState({
      current_view: view_level,
      current_view_selected: bubbleId
    })

    console.log("Current view selected:")
    console.log(bubbleId)

    this.updateGraph()
  }

  onChangeRelationClicked(event, relation_id, index){
    event.preventDefault();

    const relations = this.state.relations
    delete relations[relation_id]
    
    const relation = JSON.parse(this.state.selected_relations_text[index])
    
    const new_relation_id = relation["GENERAL"] + "_" + relation["SPECIFIC"] + "_" + relation["SOURCE_TEXT"]
    relations[new_relation_id] = relation

    const selected_relations = this.state.selected_relations
    selected_relations.splice(index, 1, new_relation_id)

    this.setState({
      relations: relations
    })
  }
  
  onAddNodeClicked(event){
    event.preventDefault();
    if(this.state.new_node_name){
      this.setState({
        nodes: this.state.nodes.add({ id: this.state.new_node_name}),
        new_node_name: ""
      })
    } else {
      alert("Please type a name first")
    }

    this.updateGraph()
  }

  onNewNodeNameTextChange(event){
    this.setState({new_node_name: event.target.value})
  }

  onRelationTextChange(event, index){
    event.preventDefault();
    const selected_relations_text = this.state.selected_relations_text       //this can probably be a one-liner but 
    selected_relations_text[index] = event.target.value
    this.setState({selected_relations_text: selected_relations_text})
  }

  onRemoveRelationClicked(event, relation_id, index){
    

    const relations = this.state.relations
    const relation = this.state.relations[relation_id]
    delete relations[relation_id]
    const selected_relations = this.state.selected_relations
    selected_relations.splice(index, 1)

    
    const links = Array.from(this.state.links)
    const link = {source: relation["GENERAL"], target: relation["SPECIFIC"]}
    for (var i = 0; i < links.length; i++){
      if(links[i]["source"]["id"] === link["source"] && links[i]["target"]["id"] === link["target"]){
        links.splice(i, 1)
      }
    }
    console.log(links)

    this.setState({
      relations: relations,
      links: new Set(links),
      selected_relations: selected_relations
    }, this.updateGraph)
  }

  onQueryRelationClicked(event){
    if(this.state.node2) {
      //var domain = document.getElementById("domains").value
      var domain = this.state.domain

      const query_set = new Set()
      for (var rel of Object.values(this.state.relations)) {
        if((this.state.node2 === rel["GENERAL"] || this.state.node2 === rel["SPECIFIC"]) && rel["SIMILARITY"][this.state.selected_similarity] >= this.state.similarity_threshold){
          query_set.add(rel["GENERAL"]).add(rel["SPECIFIC"])
        }
      }

      console.log(query_set)

      var query_string = ""
      const single_words = []
      const multi_term_words = []
      for (var term of Array.from(query_set)){
        var split_term = term.split("_")
        if(split_term.length > 1) {
          multi_term_words.push('"' + split_term.join(" ") + '"~' + (split_term.length + 2))
        } else {
          single_words.push(split_term)
        }
      }
      if(single_words.length > 0){
        query_string += '(' + single_words[0]
        for (var i=1; i < single_words.length; i++){
          query_string += " OR " + single_words[i]
        }
        query_string += ")"
      }
      if(single_words.length > 0 && multi_term_words.length > 0){
        query_string += " OR "
      }
      if(multi_term_words.length > 0){
        query_string += "(" + multi_term_words[0]
        for (i=1; i < multi_term_words.length; i++){
          query_string += " OR " + multi_term_words[i]
        }
        query_string += ')'
      }

      

      const api_key = this.state.api_key
      const url = "https://passageretrieval.artificialresearcher.com/#/results?domain=" + domain + "&userDefined=true&query=" + query_string

      this.openUrl(url)
    }
  }

  openUrl(url){
    const win = window.open(url, '_blank')
    win.focus()
  }

  onAddLinkClicked(event){
    if(this.state.node1 && this.state.node2){

      const relations = this.state.relations
      const relation = {GENERAL:this.state.node1, SPECIFIC:this.state.node2, CLASSIFICATION: ["User Defined"], SOURCE_TEXT: "This relation was created at " + Date.now(), SIMILARITY: {} }   //the time makes the text unique which prevents issues with the relations collection if someone were to create several relations between the same node without changing the text
      const relation_key = this.state.node1 + "_" + this.state.node2 + "_" + relation["SOURCE_TEXT"]
      relation["SIMILARITY"][this.state.selected_similarity] = 0.8 //it's not possible to initialize dictionaries with variables, so we'll have to do this as a second step https://stackoverflow.com/a/10640182/3565061
      relations[relation_key] = relation


      const selected_relations = []
      const selected_relations_text = []

      selected_relations.push(relation_key)
      selected_relations_text.push(JSON.stringify(relation, null, 2))


      console.log("selected_relations")
      console.log(selected_relations)
      console.log("selected_relations_text")
      console.log(selected_relations_text)


      this.setState({
        links: this.state.links.add({source:this.state.node1, target:this.state.node2}),
        relations: relations,
        selected_relations: selected_relations,
        selected_relations_text: selected_relations_text
      })
      this.updateGraph()
    } else {
      alert("First click the general node, then the specific node")
    }
  }


  onRemoveNodeClicked(event){ 
    const nodes = Array.from(this.state.nodes)
    for (var i = 0; i < nodes.length; i++){
      if(nodes[i]["id"] === this.state.node2){
        nodes.splice(i, 1)
      }
    }
    
    this.setState({
      nodes: new Set(nodes),
      node2: ""
    }, this.updateGraph)
  }

  async onDownloadClicked(event){
    event.preventDefault();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    
    const relations = this.state.relations
    if(this.state.wiki_checkbox_state) {  
      var elem = document.getElementById("progress");
      elem.style.width = 1 + "%";
      elem.innerHTML = 1 + "%";

      var limit = Object.keys(relations).length
      if (limit > 5) {
        limit = 5
      }
      for (var i = 0; i < limit; i++){
        const relation = Object.values(relations)[i]
        const payload = { "api_key": this.state.api_key, "relation": {"GENERAL": relation["GENERAL"], "SPECIFIC": relation["SPECIFIC"], SOURCE_TEXT: relation["SOURCE_TEXT"]}}
        requestOptions["body"] = JSON.stringify(payload)
        const url = `https://api.artificialresearcher.com/extend/wiki`
        const response = await fetch(url, requestOptions)
        const data = await response.json()
        console.log(data)
        relation["GENERAL_WIKI_DESCRIPTION"] = data["GENERAL_WIKI_DESCRIPTION"]
        relation["SPECIFIC_WIKI_DESCRIPTION"] = data["SPECIFIC_WIKI_DESCRIPTION"]
        relations[relation["GENERAL"] + "_" + relation["SPECIFIC"]] = relation
        this.updateDownloadProgress(i + 1, limit)
      }
    }

    const json = JSON.stringify(Object.values(relations), null, 4)

    const blob = new Blob([json], {type: 'application/json'});
    const download_element = document.createElement('a')
    const url = URL.createObjectURL(blob);
    download_element.download = "ontology_graph.json"
    download_element.href = url
    download_element.click()

    setTimeout(() => {
      var elem = document.getElementById("progress");
      elem.style.width = 0 + "%";
      elem.innerHTML = 0 + "%";
    }, 5000)
  }

  
  updateDownloadProgress(current, total){
    var elem = document.getElementById("progress");
    var width = (current/total) * 100
    elem.style.width = width + "%";
    elem.innerHTML = width + "%";
  }


  getCircleData(){
    if (this.state.domain == "patent") {
      return this.getEPOCircleData()
    } else {
      return this.getCORDCircleData()
    }
  }


  getCORDCircleData(){
    var selected = null
    console.log("current state")
    console.log(this.state.current_view)
    switch(this.state.current_view){
      case 0:  // MeSH class display A,B,C...
        var bubbles = []
        var links = []
        for (var c in this.state.onto_classes_CORD){
          bubbles.push({ id: c, count: this.state.onto_classes_CORD[c]["word_relationship_count"], mouseover_name: this.state.onto_classes_CORD[c]["description"]})
      
    for (var cr in this.state.onto_classes_CORD[c]["class_relationship_count"]){
            links.push({source: c, target: cr, count: this.state.onto_classes_CORD[c]["class_relationship_count"][cr]})
          }
        }

        return {bubbles: bubbles, links: links}
      case 1:  // MeSH class display A01, A02, B01
        var bubbles = []
        var links = []
        selected = this.state.onto_classes_CORD[this.state.current_view_selected]
        for (var c in selected){
          if(c.length == 3 && selected[c]["word_relationship_count"] > 0){
            bubbles.push({ id: c, count: selected[c]["word_relationship_count"], mouseover_name: selected[c]["description"]})
            for (var cr in selected[c]["class_relationship_count"]){
              links.push({source: c, target: cr, count: selected[c]["class_relationship_count"][cr]})
            }
          }
        }
        return {bubbles: bubbles, links: links}
      case 2:  // MeSH class display A01.236,...
        var bubbles = []
        var links = []
        selected = this.state.onto_classes_CORD[this.state.current_view_selected[0]][this.state.current_view_selected]
        for (var c in selected){
          if(c.length == 7 && selected[c]["word_relationship_count"] > 0){
            bubbles.push({ id: c, count: selected[c]["word_relationship_count"], mouseover_name: selected[c]["description"]})
            for (var cr in selected[c]["class_relationship_count"]){
              links.push({source: c, target: cr, count: selected[c]["class_relationship_count"][cr]})
            }
          }
        }
        return {bubbles: bubbles, links: links}
      default: //word relationship display.
        return {bubbles: [], links: []}

    }
  }


 getEPOCircleData(){
    var selected = null
    switch(this.state.current_view){
      case 0:  // ipc class display A,B,C
        var bubbles = []
        var links = []
        for (var c in this.state.onto_classes_EPO){
          bubbles.push({ id: c, count: this.state.onto_classes_EPO[c]["word_relationship_count"], mouseover_name: this.state.onto_classes_EPO[c]["description"]})
          for (var cr in this.state.onto_classes_EPO[c]["class_relationship_count"]){
            links.push({source: c, target: cr, count: this.state.onto_classes_EPO[c]["class_relationship_count"][cr]})
          }
        }

        return {bubbles: bubbles, links: links}
      case 1:  // ipc class display A61, A62, A63
        var bubbles = []
        var links = []
        selected = this.state.onto_classes_EPO[this.state.current_view_selected]
        for (var c in selected){
          if(c.length == 3 && selected[c]["word_relationship_count"] > 0){
            bubbles.push({ id: c, count: selected[c]["word_relationship_count"], mouseover_name: selected[c]["description"]})
            for (var cr in selected[c]["class_relationship_count"]){
              links.push({source: c, target: cr, count: selected[c]["class_relationship_count"][cr]})
            }
          }
        }
        return {bubbles: bubbles, links: links}
      case 2:  // ipc class display A61K, A61I, A61J
        var bubbles = []
        var links = []
        selected = this.state.onto_classes_EPO[this.state.current_view_selected[0]][this.state.current_view_selected]
        for (var c in selected){
          if(c.length == 4 && selected[c]["word_relationship_count"] > 0){
            bubbles.push({ id: c, count: selected[c]["word_relationship_count"], mouseover_name: selected[c]["description"]})
            for (var cr in selected[c]["class_relationship_count"]){
              links.push({source: c, target: cr, count: selected[c]["class_relationship_count"][cr]})
            }
          }
        }
        return {bubbles: bubbles, links: links}
      default: //word relationship display.
        return {bubbles: [], links: []}

    }
  }

  componentDidMount() {
    const data = {
      nodes: Array.from(this.state.nodes),
      links: Array.from(this.state.links),
      circles: this.getCircleData()
    };
    this.svg_graph = this.graph.draw(data, this.onClickLink, this.onClickNode, this.onClickBubble, this.graph_reference)
    document.getElementById("graph").appendChild(this.svg_graph)

    //alert("The Server is currently undergoing a planned maintenance. Expect outages and reduced results.")
  }

  toggleHelpPDF(){
    this.setState({
      show_help_popup: !this.state.show_help_popup
    });
  }



  handleSelectedRelationships(relationship, index){
    const rel = JSON.parse(this.state.selected_relations_text[index])
    return (<div className="aligner">
              <form id="rel-edit" onSubmit={ (ev) => this.onChangeRelationClicked(ev, relationship, index) }>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">GENERAL</label>
                  <label className="relationship-sub-area-right">{rel["GENERAL"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">SPECIFIC</label>
                  <label className="relationship-sub-area-right">{rel["SPECIFIC"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">CLASSIFICATION</label>
                  <div className="multi-label">
                  { rel["CLASSIFICATION"].map((c) => <label className="relationship-sub-area-right">{c+", "}<br/></label>) }
                  </div>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">SOURCE DATASET</label>
                  <label className="relationship-sub-area-right">{rel["SOURCE_DATASET"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">SIMILARITY</label>
                  <div className="multi-label">
                  { Object.keys(rel["SIMILARITY"]).map((sim) => <label className="relationship-sub-area-right">{sim+": "+ rel["SIMILARITY"][sim]+", "}<br/></label>)}
                  </div>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">SOURCE PDF</label>
                  <a className="relationship-sub-area-right" href={rel["SOURCE_PDF"]} target="_blank">{rel["SOURCE_PDF"]}</a>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">LANGUAGE</label>
                  <label className="relationship-sub-area-right">{rel["LANGUAGE"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">VERSION</label>
                  <label className="relationship-sub-area-right">{rel["VERSION"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">SOURCE TEXT</label>
                  <label className="relationship-sub-area-right">{rel["SOURCE_TEXT"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">TEXT LOCATION</label>
                  <label className="relationship-sub-area-right">{rel["SOURCE_TEXT_LOCATION"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                  <label className="relationship-sub-area-left">DETECTOR</label>
                  <label className="relationship-sub-area-right" type="text" id="relationship-detector" name="relationship-detector" onChange={this.onApiKeyTextChange}>{rel["DETECTOR"]}</label>
                </div><br/>
                <div className="relationship-area-left">
                </div><br/>
                <div className="relationship-area-left">
                </div>

              <input style={{visibility: 'hidden'}}type="submit" value="Change"/>
              </form><button onClick={ (ev) => this.onRemoveRelationClicked(ev, relationship, index) }>Remove</button>
            </div>)

// <input type="text" id="relationship-GENERAL" name="relationship-GENERAL" value={rel["GENERAL"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-SPECIFIC" name="relationship-SPECIFIC" value={rel["SPECIFIC"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-classification" name="relationship-classification" value={rel["CLASSIFICATION"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-SOURCE_DATASET" name="relationship-SOURCE_DATASET" value={rel["SOURCE_DATASET"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-SIMILARITY" name="relationship-SIMILARITY" value={rel["SIMILARITY"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-SOURCE_PDF" name="relationship-SOURCE_PDF" value={rel["SOURCE_PDF"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-LANGUAGE" name="relationship-LANGUAGE" value={rel["LANGUAGE"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-VERSION" name="relationship-VERSION" value={rel["VERSION"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-SOURCE_TEXT" name="relationship-SOURCE_TEXT" value={rel["SOURCE_TEXT"]} onChange={this.onApiKeyTextChange}/><br/>
// <input type="text" id="relationship-SEARCH_TEXT_LOCATION" name="relationship-SEARCH_TEXT_LOCATION" value={rel["SEARCH_TEXT_LOCATION"]} onChange={this.onApiKeyTextChange}/><br/>


  }

  clear_graph(){
    


    this.state.selected_relations = []
    this.state.selected_relations_text = []
    this.state.nodes = new Set()
    this.state.links = new Set()
    this.state.current_view = 0
    this.state.current_view_selected = null
    this.setState({
      current_view: 0,
      current_view_selected: null,
      selected_relations: [],
      selected_relations_text: [],
      nodes: new Set(),
      links: new Set(),
      node1: "",
      node2: "",

    }, this.updateGraph())
  }

  render() {
    // the graph configuration, just override the ones you need
    return (
      <div className="App" id="app">
        <div className="Graph-Field" id="graph" ref={this.graph_reference}>
          { this.state.is_loading ? <div className="loader" ref={this.graph_reference}> </div> : <div/>}
        </div>
        
        {
          this.state.show_help_popup ?
            <div className='popup'>
              <div className='popup_inner'>
                <button onClick={() => this.toggleHelpPDF()}>Close</button>
                <h1>How to use!</h1>
                <iframe title="Help" src={help_pdf} width="100%" height="95%"></iframe>
              </div>
            </div> : null
        }
        <img className="logo" src={logo} alt="logo"/>
        <div className="help">
          <br/>
          <div className="help-icon" onClick={() => this.toggleHelpPDF()}>?</div> <br/><br/><br/>
          <div className="help-icon" onClick={() => this.openUrl("https://forms.office.com/pages/responsepage.aspx?id=Bm5ROwIhzUCIvIv1b3-AFfKFZnnGIZ5JiFjqFUZ5wUFURVJGQk5IRkFSRDBZMlBWQ1VVWTdKOEtLTS4u")}>!</div>
        </div>
        <div className="sidebar">
          <form onSubmit={this.onSubmitClicked}>
            <label className="leftlabel" htmlFor="api_key">Api Key:</label>
            <input type="text" id="api_key" name="api_key" placeholder="Your api Key" value={this.state.api_key} onChange={this.onApiKeyTextChange}/><br/>
            <label className="leftlabel" htmlFor="domains">Domain:   </label>
            <select className="leftlabel" name="domains" id="domains" onChange={(e) => this.setState({domain: e.target.value, selected_similarity: this.state.domain_similarity[e.target.value][0]}, () => {this.clear_graph()}) }>
              <option value="patent">Patent</option>
              <option value="covid">Covid</option>
            </select><br/><br/>
            <label className="leftlabel" htmlFor="term">Term:</label>
            <input type="checkbox" id="term-cb" name="term-cb" onChange={this.onTermCheckboxStateChange} defaultChecked={this.state.term_checkbox_state}/><br/>
            <input type="text" id="term" name="term" ref={this.term_input_reference} placeholder="disease" value={this.state.term} onChange={this.onTermTextChange}/> <br/>
            <label className="leftlabel" htmlFor="classification">Classification:</label>
            <input type="checkbox" id="classification-cb" name="classification-cb" onChange={this.onClassificationCheckboxStateChange} defaultChecked={this.state.classification_checkbox_state}/><br/>
            <input type="text" id="classification" name="classification" ref={this.classification_input_reference} placeholder="A01.236" value={this.state.classification} onChange={this.onClassificationTextChange} /><br/>
            <label className="leftlabel" htmlFor="max_relations">Max Relations: </label> <label htmlFor="max_relations"> {this.state.max_relations}</label><br/>
            <input type="range" id="max_relations" name="max_relations" min="5" max="2000" value={this.state.max_relations} onChange={this.onMaxRelationsChange} /><br/>
            {
              <select className="leftlabel" name="similarities" onChange={(e) => this.setState({selected_similarity: e.target.value })} >
                {
                    this.state.domain_similarity[this.state.domain].map((val) => <option value={val}>{val}</option>)
                }
              </select>
            }
            <label className="leftlabel" htmlFor="similarity_threshold">Similarity Threshold: </label><label className="leftlabel" htmlFor="similarity_threshold"> {this.state.similarity_threshold}</label><br/><br/>
            <input type="range" id="similarity_threshold" name="similarity_threshold" min=".5" max=".99" step="0.01" value={this.state.similarity_threshold} onChange={this.onSimilarityThresholdChange} /><br/>
            <input type="submit" value="Submit" />
          </form><br/>
          { this.state.selected_relations != [] ? <button className='rightlabel' onClick={() => this.setState({popout_selected_relationship: true})}>Popout </button> : null }
                 
          { this.state.popout_selected_relationship ?
          
              <div className='popup'>
                <div className='popup_inner'>
                  <button className='rightlabel' onClick={() => this.setState({popout_selected_relationship: false})}>Close</button>
                  <br/><br/><br/>
                    <div className="relationship-area">
                      { this.state.selected_relations.map(this.handleSelectedRelationships, this)}
                    </div>
                </div>
              </div> :
                <div className="relationship-area">
                  <br/>
                  { this.state.selected_relations.map(this.handleSelectedRelationships, this)}
                </div>
          }
          
          <form id="add-node" onSubmit={this.onAddNodeClicked}>
            <label className="leftlabel" htmlFor="new_node_name">New Node Name:</label><br/>
            <input type="text" id="new_node_name" name="new_node_name" placeholder="new" value={this.state.new_node_name} onChange={this.onNewNodeNameTextChange} /><br/>
            <input type="submit" value="Add Node" />
          </form>
          <label><pre>Currently selected Node:</pre></label>
          <label><pre>{this.state.node2}</pre></label>
          <button onClick={this.onQueryRelationClicked}>Query Node</button><br/>
          <button onClick={this.onRemoveNodeClicked}>Remove Node</button><br/><br/>          
          <label><pre>Link Nodes: </pre></label>
          <label><pre>General   -   Specific</pre></label>
          <label><pre>{this.state.node1}    -   {this.state.node2}</pre></label>
          
          <button onClick={this.onAddLinkClicked}>Create Link</button><br/><br/>
          
          <form id="rel-edit" onSubmit={this.onDownloadClicked}>
            <input type="submit" value="Download"/>
            <input type="checkbox" id="wiki-cb" name="wiki-cb" defaultChecked={this.state.wiki_checkbox_state} onChange={this.onWikiCheckboxStateChange} />
            <label htmlFor="checkbox">with Wiki (slow, max 5)</label>
          </form>
          <div id="progressBar">
            <div id="progress">0%</div>
          </div>

        </div>
      </div>
    );
  }
}

export default App;